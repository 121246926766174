import { getCookies } from '../../../../../utils/cookies';

const MAPPING = {
  login: 'connexion',
  registration: 'inscription',
  forgot_password: 'mot_de_passe_oublie',
  'reset-password': 'reinitialiser-le-mot-de-passe',
};

/**
 * @param {Object} data
 * @return {Object}
 */
export const security = (data) => {
  const cookies = getCookies();

  switch (data.action) {
    case 'success':
      return {
        click: data.click || document.referrer.split('://')[1].split('/')[1],
        login_status: 'true',
        login_stay_connected: cookies.lumni_student_remember_me === 'true',
        opt_in_newsletter: cookies.lumni_nl_registered === 'true',
      };
    case 'error':
      return {
        click: data.click || MAPPING[window.LUMNI_CONTEXT.route],
        login_status: 'false',
        error_message: data.error_message,
      };
    default:
      return {};
  }
};

import PianoClick from './PianoClick';
import { base } from './events/load/base';
import { qualification } from './events/load/qualification';
import { content } from './events/load/content';
import { user } from './events/load/user';
import { search } from './events/load/search';
import { removeEmptyValue, formatStringValue } from './utils/format';
import PianoVirtualPage from './PianoVirtualPage';
import PianoImpression from './PianoImpression';
import PianoLogin from './PianoLogin';
import PianoEventManager from './PianoEventManager';

class Piano {
  /**
   * @param {object} defaultDataLayer
   */
  constructor(defaultDataLayer) {
    this.dataLayer = defaultDataLayer;
    this.eventManager = new PianoEventManager();
    this._init();
  }

  /**
   * @private
   */
  _init() {
    this._setEventsOnLoad();
    this._setEventsOnClick();
    this._setEventsForImpression();
    this._setEventsForLogin();
    this._setEventsForVirtualPage();
  }

  /**
   * @private
   */
  _setEventsOnLoad() {
    this._setBase();
    this._setQualification();
    this._setContent();
    this._setSearch();
    this._setUser();
  }

  /**
   * @private
   */
  _setEventsOnClick() {
    (new PianoClick(this.eventManager)).init();
  }

  /**
   * @private
   */
  _setEventsForVirtualPage() {
    (new PianoVirtualPage(this.eventManager)).init();
  }

  /**
   * @private
   */
  _setEventsForImpression() {
    (new PianoImpression(this.eventManager)).init();
  }

  /**
   * @private
   */
  _setEventsForLogin() {
    (new PianoLogin(this.eventManager)).init();
  }

  /**
   * @private
   */
  _setBase() {
    this.dataLayer = { ...this.dataLayer, ...base() };
  }

  /**
   * @private
   */
  _setQualification() {
    this.dataLayer = { ...this.dataLayer, ...qualification() };
  }

  /**
   * @private
   */
  _setContent() {
    this.dataLayer = { ...this.dataLayer, ...content() };
  }

  /**
   * @private
   */
  _setUser() {
    this.dataLayer = { ...this.dataLayer, ...user() };
  }

  /**
   * @private
   */
  _setSearch() {
    this.dataLayer = {
      ...this.dataLayer,
      ...search(this.dataLayer.origin_page, this.dataLayer.origin_page_type),
    };
  }

  /**
   * @private
   */
  _removeEmptyValue() {
    this.dataLayer = removeEmptyValue(this.dataLayer);
  }

  /**
   * @private
   */
  _formatStringValue() {
    this.dataLayer = formatStringValue(this.dataLayer);
  }

  /**
   * @private
   */
  _addTabStringValue() {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    const tabs = {
      profile: { name: '1' },
      content: { name: '2' },
      history: { name: '3' },
      settings: { name: '4' },
    };

    this.dataLayer.tab = Object.prototype.hasOwnProperty.call(tabs, tab)
      ? tabs[tab].name
      : tabs.profile.name;
  }

  getDataLayer() {
    this._removeEmptyValue();
    this._formatStringValue();
    if (this.dataLayer.page === 'avantage_profil') {
      this._addTabStringValue();
    }

    return this.dataLayer;
  }
}

export default Piano;

import { getCookies } from '../../../../../utils/cookies';
import { isConnected } from '../../../../security/session/user';

export const user = () => {
  const cookies = getCookies();
  const stayConnectedCookie = cookies.lumni_student_remember_me === 'true';
  const optInNewsletterCookie = cookies.lumni_nl_registered === 'true';
  const userId = cookies.lumni_student_user_id;

  return {
    login_status: isConnected().toString(),
    login_stay_connected: stayConnectedCookie.toString(),
    opt_in_newsletter: optInNewsletterCookie.toString(),
    visitor_login_status: isConnected().toString(),
    user_id: userId || '',
  };
};

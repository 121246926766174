import { newsletter } from './events/impression/newsletter';
import { popinAccount } from './events/impression/popin_account';
import { popinIncentive } from './events/impression/popin_incentive';
import { popinBackToSchool } from './events/impression/popin_back_to_school';
import { share } from './events/impression/share';
import { popinGamification } from './events/impression/popin_gamification';
import { popinGamificationLevel } from './events/impression/popin_gamification_level';
import { popinGamificationBadge } from './events/impression/popin_gamification_badge';
import { accountGamification } from './events/impression/account_gamification';
import { incitationProfile } from './events/impression/incitation_profile';
import { incitationNewsletter } from './events/impression/incitation_newsletter';
import { popinAdImpression } from './events/impression/popin_ad_impression';

const IMPRESSION_EVENT = 'Tracking:impression';

class PianoImpression {
  /**
   * @param {PianoEventManager} eventManager
   */
  constructor(eventManager) {
    this.eventManager = eventManager;
    this.events = {
      newsletter,
      share,
      popin_account: popinAccount,
      popin_incentive: popinIncentive,
      popin_back_to_school: popinBackToSchool,
      popin_gamification: popinGamification,
      popin_gamification_level: popinGamificationLevel,
      popin_gamification_badge: popinGamificationBadge,
      account_gamification: accountGamification,
      incitation_compte: incitationProfile,
      incitation_newsletter: incitationNewsletter,
      popin_ad_impression: popinAdImpression,
    };
  }

  init() {
    document.addEventListener(IMPRESSION_EVENT, this._onImpression.bind(this), false);
  }

  /**
   * @param {string} type
   * @param {object} data
   * @private
   */
  _getData(type, data) {
    return type in this.events ? this.events[type](data) : null;
  }

  /**
   * @param {object} detail
   * @private
   */
  _onImpression({ detail }) {
    this.eventManager.send('impression', this._getData(detail.type, detail.data));
  }
}

export default PianoImpression;
